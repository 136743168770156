@charset "UTF-8";
* {
  box-sizing: border-box !important; }

body {
  margin: 0;
  padding: 0;
  border: 0;
  outline: none;
  list-style: none;
  text-decoration: none; }

p {
  margin-bottom: 15px;
  line-height: 1.2em; }

.footer {
  font-size: 13px !important;
  line-height: 18px !important; }

ul li, ol li {
  margin-bottom: 12px; }
  @media (max-width: 800px) {
    ul li, ol li {
      margin-bottom: 10px; } }
  ul li > ul, ol li > ul {
    margin-top: 10px; }

.content {
  padding-top: 60px;
  padding-bottom: 60px; }
  @media (max-width: 800px) {
    .content {
      padding-top: 20px;
      padding-bottom: 20px; } }

strong {
  font-family: "ubuntu-bold" !important;
  font-weight: normal !important; }

body {
  font-family: ubuntu-reg, arial, tahoma, _sans;
  line-height: 1.2em;
  font-size: 21px; }
  @media (max-width: 800px) {
    body {
      font-size: 15px;
      line-height: 1.1em; } }

.h1-h6, h1, h2, h3, h4, h5, h6 {
  font-weight: normal !important;
  font-family: "merriweather-black";
  line-height: 1.2em;
  margin-top: 10px;
  margin-bottom: 10px; }
  @media max-width {
    .h1-h6, h1, h2, h3, h4, h5, h6 {
      line-height: 1.1em; } }

h1 {
  font-size: 2.2em; }
  @media max-width {
    h1 {
      font-size: 1.5em; } }

h2 {
  font-size: 2em; }
  @media max-width {
    h2 {
      font-size: 1.3em; } }

h3 {
  font-size: 1.6em; }
  @media max-width {
    h3 {
      font-size: 1.5em; } }

h4 {
  font-size: 1.4em; }
  @media max-width {
    h4 {
      font-size: 1.3em; } }

h5 {
  font-size: 1.2em; }

h6 {
  font-size: 1em; }

.t0 {
  margin-top: 0; }

.t5 {
  margin-top: 5px; }

.t10 {
  margin-top: 10px; }

.t15 {
  margin-top: 15px; }

.t20 {
  margin-top: 20px; }

.t30 {
  margin-top: 30px; }

.t40 {
  margin-top: 40px; }

.t50 {
  margin-top: 50px; }

.t60 {
  margin-top: 60px; }

.t65 {
  margin-top: 65px; }

.t75 {
  margin-top: 75px; }

.l10 {
  margin-left: 10px; }

.l20 {
  margin-left: 20px; }

.r20 {
  margin-right: 20px; }

.r40 {
  margin-right: 40px; }

.b0 {
  margin-bottom: 0; }

.b5 {
  margin-bottom: 5px; }

.b10 {
  margin-bottom: 10px; }

.b15 {
  margin-bottom: 15px; }

.b20 {
  margin-bottom: 20px; }

.b25 {
  margin-bottom: 25px; }

.b30 {
  margin-bottom: 30px; }

.b35 {
  margin-bottom: 35px; }

.b40 {
  margin-bottom: 40px; }

.b50 {
  margin-bottom: 50px; }

.b60 {
  margin-bottom: 60px; }

.btn-transparent {
  border-radius: 1000px;
  width: 270px;
  height: 60px;
  line-height: 60px;
  display: inline-block;
  font-size: 1.3em;
  color: black;
  background-color: transparent;
  font-family: "ubuntu-bold";
  border: 1px solid #ffffff;
  text-transform: uppercase; }
  @media (max-width: 800px) {
    .btn-transparent {
      width: 100%; } }
  .btn-transparent:hover {
    color: #000000;
    background-color: #ffffff; }

ul.no-indent {
  list-style: none;
  padding-left: 0; }

.dash-marker li {
  padding-left: 1.2em;
  text-indent: -1.2em; }
  .dash-marker li::before {
    content: '—';
    margin-right: 5px; }

iframe {
  border: 0; }

@media (max-width: 800px) {
  .l10, .l20, .r20, .r40 {
    margin-left: 0;
    margin-right: 0; }
  .t30,
  .t40 {
    margin-top: 20px; }
  .t50,
  .t60,
  .t65,
  .t75 {
    margin-top: 25px; }
  .b30,
  .b40,
  .b50,
  .b60 {
    margin-bottom: 20px; } }

body a {
  color: #000; }
  body a:hover, body a:focus {
    text-decoration: none;
    color: #000; }

body ul {
  padding-left: 25px; }

.header, [class*="block"] {
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover; }
  .header .embed-responsive, .header img, [class*="block"] .embed-responsive, [class*="block"] img {
    margin-bottom: 20px;
    background-color: transparent; }
  .header .author, [class*="block"] .author {
    float: right;
    font-family: "ubuntu-it"; }
  .header .course-appliance-btn, [class*="block"] .course-appliance-btn {
    border-radius: 1000px;
    width: 270px;
    height: 60px;
    line-height: 60px;
    display: inline-block;
    font-size: 1.3em;
    color: #fff;
    background-color: #ff1e53; }
    @media (max-width: 800px) {
      .header .course-appliance-btn, [class*="block"] .course-appliance-btn {
        width: 100%; } }

.header, .priority-1, .priority-2 {
  font-family: ubuntu-reg, arial, tahoma, _sans;
  line-height: 1.1em; }

.header, .priority-1 {
  font-size: 1.2em;
  line-height: 1.3em; }

.priority-2 {
  font-size: 1.3em;
  line-height: 1.3em; }

.title-headliner {
  margin-bottom: 30px;
  font-family: "merriweather-black";
  font-size: 2.1em;
  line-height: 1.1em;
  color: #ff1e53; }
  @media (max-width: 800px) {
    .title-headliner {
      font-size: 1.5em; } }

.color-sketch {
  color: #ff1e53; }

.block5, .block10, .color-light {
  color: #fff; }

.header {
  background-image: url("../../i/header-bg.jpg"); }
  .header .content {
    padding-top: 80px;
    padding-bottom: 80px; }
  .header h1 {
    font-family: "merriweather-ubold";
    font-size: 3.1em;
    line-height: 0.95em;
    color: #ff1e53; }
    @media (max-width: 800px) {
      .header h1 {
        font-size: 1.9em; } }
  .header .landing-about {
    line-height: 1.1em; }
  .header .online-course {
    border-radius: 1000px;
    margin-top: 200px;
    margin-left: auto;
    margin-right: auto;
    padding: 20px;
    max-width: 700px;
    font-family: "ubuntu-med";
    background-color: rgba(255, 255, 255, 0.6); }
    @media (max-width: 800px) {
      .header .online-course {
        border-radius: 0px;
        margin-top: 50px; } }
    .header .online-course img {
      margin-bottom: 0; }
    .header .online-course strong {
      color: #ff1e53; }

.block1 {
  font-family: "ubuntu-med"; }
  .block1 .course-register-btn {
    border-radius: 1000px;
    width: 430px;
    height: 60px;
    line-height: 60px;
    display: inline-block;
    font-size: 1.3em;
    color: #fff;
    background-color: #ff1e53;
    margin-top: 40px; }
    @media (max-width: 800px) {
      .block1 .course-register-btn {
        width: 100%; } }

.block2 {
  background-image: url("../../i/block2-bg.jpg");
  background-position-y: bottom; }
  .block2 ul {
    margin-bottom: 50px; }
    @media (max-width: 800px) {
      .block2 ul {
        margin-bottom: 25px; } }

.block3 .priority-1 {
  line-height: 1.1em; }

.block4 {
  background-image: url("../../i/block4-bg.jpg");
  background-position-x: left;
  background-color: #f6fec2; }
  @media (max-width: 800px) {
    .block4 {
      padding-top: 500px;
      background-size: 800px; } }
  .block4 .title-headliner {
    color: #000; }
  .block4 .author {
    font-family: "ubuntu-bold-it"; }

.block5 {
  background-color: #ff1d52; }
  .block5 .title-headliner {
    color: #f6fec2; }
  .block5 ol {
    font-family: "ubuntu-bold";
    font-size: 1.1em; }
  .block5 li p {
    padding-left: 15px;
    font-family: ubuntu-reg, arial, tahoma, _sans;
    font-size: 0.9em; }

.block6 {
  background-image: url("../../i/block6-bg.jpg"); }
  .block6 ul {
    list-style: none; }
  .block6 ul {
    margin-bottom: 30px;
    padding: 0; }

.block7 .narrator {
  margin-bottom: 30px;
  font-family: "merriweather-black";
  font-size: 1.3em;
  color: #ff1e53; }

.block8 {
  background-image: url("../../i/block8-bg.jpg"); }

.block9 {
  background-image: url("../../i/block9-bg.jpg"); }
  .block9 .price-block {
    margin-bottom: 30px;
    padding: 25px;
    position: relative;
    height: 665px;
    background-color: #fff; }
    .block9 .price-block ul {
      list-style: none; }
    @media (max-width: 800px) {
      .block9 .price-block {
        height: auto; } }
    .block9 .price-block strong {
      color: #ff1e53; }
  .block9 .course-name {
    font-size: 1.1em; }
  .block9 .modules-list {
    padding: 0;
    font-family: "ubuntu-lt";
    font-size: 0.9em;
    text-align: left; }
  .block9 .appliance {
    margin: 20px 0;
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 0; }
    @media (max-width: 800px) {
      .block9 .appliance {
        position: static; } }
  .block9 .price {
    font-family: "merriweather-black";
    font-size: 2.5em; }
  .block9 .course-appliance-btn {
    border-radius: 1000px;
    width: 220px;
    height: 60px;
    line-height: 60px;
    display: inline-block;
    font-size: 1.3em;
    color: #fff;
    background-color: #ff1e53; }
    @media (max-width: 800px) {
      .block9 .course-appliance-btn {
        width: 100%; } }

.block10 {
  background-color: #ff1d52;
  font-family: "ubuntu-lt"; }

.block11 {
  background-image: url("../../i/block11-bg.jpg");
  background-position-y: bottom; }
  .block11 ul {
    list-style: none; }
  .block11 .priority-2 {
    font-family: "merriweather-black"; }
  .block11 .package-features-list {
    padding-left: 0; }
    .block11 .package-features-list > li:not(:last-child) {
      margin-bottom: 50px; }
  .block11 .feature-item {
    list-style-type: disc; }
    .block11 .feature-item li {
      margin-bottom: 0; }
    .block11 .feature-item li:last-child {
      margin-bottom: 15px; }

.block12 {
  background-image: url("../../i/block12-bg.jpg");
  background-position-y: bottom; }
  .block12 .review {
    margin-bottom: 50px;
    font-size: 0.7em;
    text-align: left; }

.block13 .author {
  max-width: 250px; }
  @media (max-width: 800px) {
    .block13 .author {
      max-width: 150px; } }

.block14.footer {
  font-size: 0.8em;
  background-color: #e2e8bd; }
  .block14.footer ul {
    list-style: none; }
  .block14.footer .contacts {
    padding: 0;
    font-family: "ubuntu-lt"; }
  .block14.footer li {
    margin-bottom: 0; }
  .block14.footer .social-media-list {
    padding: 0; }
    .block14.footer .social-media-list li {
      display: inline-block; }
      .block14.footer .social-media-list li:not(:last-child) {
        margin-right: 20px; }
    .block14.footer .social-media-list a {
      height: 45px;
      width: 45px;
      display: inline-block;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover; }
  .block14.footer .inst {
    background-image: url("../../i/inst.png"); }
  .block14.footer .fb {
    background-image: url("../../i/fb.png"); }
  .block14.footer .vk {
    background-image: url("../../i/vk.png"); }
  .block14.footer .youtube {
    background-image: url("../../i/youtube.png"); }

/*NAVBAR MENU*/
nav {
  background-color: #ff1e53; }
  nav .navbar-header button.navbar-toggle {
    font-family: "ubuntu-bold";
    color: #ffffff; }
  nav .navbar-collapse ul li {
    margin-bottom: 0; }
    nav .navbar-collapse ul li a {
      font-family: "ubuntu-bold";
      font-size: 15px;
      color: #ffffff;
      text-transform: uppercase;
      text-shadow: none; }
      nav .navbar-collapse ul li a:hover, nav .navbar-collapse ul li a:focus {
        background-color: #ea0037; }

.collapse {
  display: block; }

.navbar-toggle {
  display: none; }

.navbar-nav {
  float: left !important; }

.navbar-nav:last-child {
  float: right !important; }

.navbar-nav li {
  float: left; }

.mobile-nav .navbar-center {
  text-align: left; }

.mobile-nav .navbar-center > ul {
  float: left;
  display: block; }

.mobile-nav .navbar-header {
  float: none; }

.mobile-nav .navbar-left, .navbar-right {
  float: none !important; }

.mobile-nav .navbar-toggle {
  display: block; }

.mobile-nav .navbar-collapse {
  border-top: 1px solid transparent;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1); }

.mobile-nav .navbar-fixed-top {
  top: 0;
  border-width: 0 0 1px; }

.mobile-nav .navbar-collapse.collapse {
  display: none !important; }

.mobile-nav .navbar-nav {
  float: none !important; }

.mobile-nav .navbar-nav > li {
  float: none; }

.mobile-nav .navbar-nav > li > a {
  padding-top: 10px;
  padding-bottom: 10px; }

.mobile-nav .collapse.in {
  display: block !important; }
